<template>
	<v-sheet class="location" id="location" style="height: calc(100vh - 88px)">
		<CreateDialog
			:dialog="createDialog"
			v-on:close="
				createDialog = false;
				clearLocalData();
			"
			v-on:scroll="hideOnScroll($event)"
		>
			<template v-slot:title>
				<span>{{ pageTitle() }}</span>
				<span class="orange--text text--darken-4 barcode-font-size">
					#{{ purchase_order.barcode }}</span
				>
			</template>
			<template v-if="scroll_top" v-slot:additional>
				<span>{{ formatMoney(line_items_calculations.total) }}</span>
			</template>
			<template v-slot:body>
				<template v-if="!barcodeLoading">
					<v-form
						ref="serviceForm"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="updateOrCreate"
					>
						<v-row>
							<v-col md="10">
								<v-row class="px-4">
									<v-col md="2" class="my-auto py-0">
										<label for="po-number" class="btx-label mt-2">
											Ref Number
											<TooltipQuestion>
												<template v-slot:text>Reference Number Printed on pdf</template>
											</TooltipQuestion>
										</label>
									</v-col>
									<v-col md="4" class="py-0">
										<TextInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="po-number"
											placeholder="Ref Number"
											v-model="purchase_order.reference_number"
											@change="savetoLocal()"
										>
										</TextInput>
									</v-col>
									<v-col md="2" class="py-0" v-if="false">
										<label for="description" class="btx-label mt-2">Description</label>
									</v-col>
									<v-col md="9" class="py-0" v-if="false">
										<TextAreaInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="description"
											placeholder="Description"
											v-model="services.description"
										>
										</TextAreaInput>
									</v-col>
									<v-col md="2" class="my-auto py-0 text-right">
										<label for="Delivery-Date" class="btx-label mt-2 text-right required"
											>Delivery Date
										</label>
									</v-col>
									<v-col md="3" class="py-0">
										<DatePicker
											hide-details
											:min-date="minDate"
											:disabled="pageLoading"
											:loading="pageLoading"
											id="Delivery-Date"
											placeholder="Delivery Date"
											v-model="purchase_order.date"
											@change="savetoLocal()"
											:rules="[vrules.required(purchase_order.date, 'Delivery Date')]"
											:class="{
												required: !purchase_order.date,
											}"
										></DatePicker>
									</v-col>
									<v-col md="2" class="mt-2 py-0">
										<label for="customer" class="btx-label mt-2">Customer</label>
									</v-col>
									<v-col md="9" class="py-0">
										<SelectCustomerSupplier
											v-model="customer"
											:items="customerList"
											:update-detail="selectedCustomer"
											type="customer"
											placeholder="Customer"
											input-id="customer-id"
											@change="getRelatedProject($event), savetoLocal()"
										></SelectCustomerSupplier>
									</v-col>

									<template v-if="customer || customer?.id">
										<v-col md="2" class="my-auto py-0">
											<label for="Project" class="btx-label mt-2">Project</label>
										</v-col>
										<v-col md="9" class="py-0">
											<AutoCompleteInput
												hide-details
												:items="projectList"
												:disabled="pageLoading"
												:loading="pageLoading"
												id="Project"
												placeholder="Project"
												v-model="purchase_order.project"
												@change="savePurchaseOrderToLocal(), getRelatedBudget($event)"
											>
											</AutoCompleteInput>
										</v-col>
									</template>
									<v-col md="2"> </v-col>
									<v-col md="9">
										<div>
											<div class="red--text mt-4" style="font-size: 10px; margin-bottom: -13px">
												Note: Changing supplier will reset all line items.
											</div>
										</div>
									</v-col>
									<v-col md="2" class="mt-2 py-0">
										<label for="supplier" class="btx-label mt-2 required">Supplier</label>
									</v-col>
									<v-col md="9" class="py-0">
										<SelectSupplier
											v-model="supplier"
											:items="supplierList"
											:update-detail="selectedSupplier"
											type="supplier"
											placeholder="Supplier"
											input-id="supplier-id"
											:rules="[vrules.required(supplier.id, 'Supplier')]"
											:class="{
												required: !supplier.id,
											}"
											@input="changeFrontendUUID($event)"
											@change="supplierChange($event)"
										></SelectSupplier>
									</v-col>
									<v-col md="2"></v-col>
									<v-col md="9">
										<v-checkbox
											hide-details
											label="Show Only this supplier product"
											class="my-2 p-0"
											@change="showSupplierProductChange"
											:true-value="1"
											:false-value="0"
											v-model="purchase_order.show_only_supplier_products"
										></v-checkbox>
									</v-col>
									<v-col md="2" class="py-0">
										<label for="shipping_terms" class="btx-label mt-4">Shipping terms</label>
									</v-col>
									<v-col md="9" class="py-0">
										<!-- <TextAreaInput
											:disabled="pageLoading"
											:loading="pageLoading"
											id="shipping_terms"
											placeholder="shipping terms"
											v-model="purchase_order.shipping_terms"
											@change="savetoLocal()"
										>
										</TextAreaInput> -->
										<TextInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="shipping_terms"
											placeholder="Shipping terms"
											v-model="purchase_order.shipping_terms"
											@change="savetoLocal()"
										>
										</TextInput>
									</v-col>
									<v-col md="2" class="mt-2 py-0" v-show="purchase_order?.project">
										<label for="supplier" class="btx-label mt-2">Budget Category</label>
									</v-col>
									<v-col md="9" class="py-0" v-show="purchase_order?.project">
										<SelectBudget
											hide-details
											return-object
											id="Budget_category"
											:items="budgetCategoryList"
											placeholder="Budget Category"
											v-model="budgetCategory"
											:disabled="pageLoading"
											:loading="pageLoading"
											@setModel="setCategory"
										>
										</SelectBudget>
									</v-col>
								</v-row>
							</v-col>
							<v-col md="2">
								<v-row>
									<v-col md="12" class="py-0 text-right">
										<ImageUpload
											v-if="false"
											can-change
											v-model="services.image"
											:page-loading="pageLoading"
										></ImageUpload>
										<div class="py-1 mb-2" v-if="false">
											<span> # </span
											><b class="orange--text text--darken-4" style="font-size: 16px">{{
												purchase_order.barcode
											}}</b>
										</div>
										<span class="fw-500" style="font-size: 24px">Amount</span><br />
										<div class="blue--text text--darken-4 fw-500 mt-5" style="font-size: 30px">
											{{ formatMoney(line_items_calculations.total) }}
										</div>
									</v-col>
								</v-row>
							</v-col>
							<v-col md="12 middle-header-background">
								<div class="middle-header-background d-flex align-items-center justify-content-between">
									<div>
										<p class="mb-2 px-4">
											Line items<br />
											<span
												><v-icon small>mdi-progress-question</v-icon> Specify the Line Items for your Purchase
												order</span
											>
										</p>
									</div>
									<v-btn
										v-if="$route.name == 'purchase-order-create'"
										class="ml-3"
										:disabled="pageLoading || !supplier.id"
										tile
										deppressed
										color="blue darken-4 white--text"
										@click="importExcelFile()"
									>
										<v-icon size="16">mdi-file-excel</v-icon>
										Upload Excel</v-btn
									>
								</div>
							</v-col>
							<v-col md="12">
								<POLineItems
									ref="poLineItemsRef"
									@searchLinItems="searchLinItems"
									:show_only_supplier_products="purchase_order.show_only_supplier_products"
									:flaggedLineItems="flaggedLineItems"
									@updateFlaggedLineItems="updateFlaggedLineItems"
									v-model="poLineItems"
									:id="purchase_order.id"
									:supplier="supplier.id"
									:calculations="line_items_calculations"
									@update:total="getCalculation($event)"
								></POLineItems>
							</v-col>
							<v-col md="12">
								<p class="middle-header-background mb-2 px-4">
									Attachments & Other details<br />
									<span>
										<v-icon small>mdi-progress-question</v-icon> Specify the Attachments & Other details for
										your Purchase order</span
									>
								</p>
							</v-col>
							<v-col md="10">
								<v-row>
									<v-col md="2" class="mt-2 py-0">
										<label for="remark" class="btx-label mt-2">Attachments</label>
									</v-col>
									<v-col md="9" class="py-0">
										<FileUpload v-model="purchase_order.files"></FileUpload>
									</v-col>
									<v-col md="12" class="py-5"> </v-col>
									<v-col md="2" class="mt-2 py-0">
										<label for="remark" class="btx-label mt-2">
											Remark
											<TooltipQuestion>
												<template v-slot:text>Remark Printed on pdf</template>
											</TooltipQuestion>
										</label>
									</v-col>
									<v-col md="9" class="py-0">
										<TextAreaInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="remark"
											placeholder="Remark"
											v-model="purchase_order.admin_remark"
											@change="savetoLocal()"
										>
										</TextAreaInput>
									</v-col>
									<v-col md="2" class="py-0">
										<label for="terms-and-conditions" class="btx-label mt-2">Terms & Conditions</label>
									</v-col>
									<v-col md="9" class="py-0">
										<SelectTermsCondition
											v-model="terms_and_condition"
											label="Terms & conditions"
											placeholder="Terms & conditions"
											type="purchase-order"
											append-outer-icon="mdi-cog"
										></SelectTermsCondition>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-form>
				</template>
				<template v-else>
					<div class="mx-20 my-40 text-center">
						<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
					</div>
				</template>
			</template>
			<template v-slot:action>
				<v-btn
					tile
					depressed
					color="blue darken-4"
					class="white--text"
					:loading="pageLoading"
					:disabled="pageLoading"
					@click="updateOrCreate"
				>
					Save
				</v-btn>
			</template>
		</CreateDialog>
		<ManageCategory
			:dialog="manageCategoryDialog"
			:category="groupList"
			v-on:close-dialog="manageCategoryDialog = false"
			v-on:success="getCategories"
		></ManageCategory>
	</v-sheet>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE, GET_PROJUCT_DROPDOWN } from "@/core/services/store/common.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import {
	GetCustomer,
	CreatePurchaseOrder,
	UpdatePurchaseOrder,
	GetPurchaseOrder,
	LineItemsMainToTemp,
	// ImportLineItems,
} from "@/core/lib/purchase-order.lib";
import TextInput from "@/view/components/TextInput";
import DatePicker from "@/view/components/DatePicker";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
// import Dialog from "@/view/components/Dialog";
import TextAreaInput from "@/view/components/TextAreaInput";
import SelectSupplier from "@/view/components/SelectSupplier";
import ImageUpload from "@/view/components/ImageUpload";
import ManageCategory from "@/view/components/Manage-Category";
import CreateDialog from "@/view/components/CreateDialog";
import POLineItems from "@/view/components/POLineItems";
import FileUpload from "@/view/components/FileUpload";
import SelectTermsCondition from "@/view/components/SelectTermsCondition";
import SelectCustomerSupplier from "@/view/components/SelectCustomerSupplier";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import moment from "moment-timezone";
import { EventBus } from "@/core/event-bus/event.bus";
// import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import { filter, map } from "lodash";
import { saveData, getData, deleteData } from "@/core/services/local.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GetAllBudget } from "@/core/lib/common.lib";
import SelectBudget from "@/view/components/SelectBudget";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { SearchLineItem } from "@/core/lib/quotation.lib";
export default {
	name: "Purchase-order-create",
	title: "Create Purchase Order",
	data() {
		return {
			flaggedLineItems: [],
			uuid: null,
			barcode: null,
			formValid: true,
			scroll_top: false,
			createDialog: true,
			pageLoading: false,
			contentLoading: false,
			importDialog: false,
			manageCategoryDialog: false,
			customSupplierId: null,
			minDate: new Date().toISOString().substr(0, 10),
			terms_and_condition: {
				id: null,
				description: null,
			},
			upload_excel: {
				type: null,
				file: null,
			},
			location: {
				name: null,
				id_number: null,
				location: null,
				latitude: null,
				longitude: null,
				address_line_1: null,
				address_line_2: null,
				address_city: null,
				address_state: null,
				address_country: "Singapore",
				address_postal_code: null,
				description: null,
				has_lat_long: 0,
			},
			services: {
				name: null,
				description: null,
				reference: null,
				barcode: null,
				charges: null,
				category: null,
				image: null,
			},
			line_items_calculations: {},
			selectedCustomer: {
				id: null,
				billing_address_id: null,
				contact_person_id: null,
			},
			customer: {
				id: null,
				billing_address_id: null,
				contact_person_id: null,
			},
			selectedSupplier: {
				id: null,
				billing_address_id: null,
				contact_person_id: null,
			},
			supplier: {
				id: null,
				billing_address_id: null,
				contact_person_id: null,
			},
			purchase_order: {
				purchase_order_number: null,
				reference_number: null,
				id: null,
				barcode: null,
				date: null,
				customer: null,
				show_only_supplier_products: 1,
				shipping_terms: null,
				customer_billing_address: null,
				contact_person: null,
				supplier_billing_address: null,
				supplier: null,
				project: null,
				files: [],
				/* terms_and_conditions: null,
				terms_and_conditions_text: null, */
				admin_remark: null,
			},
			groupList: [],
			termsAndConditionList: [{ value: 1, text: "N-20" }],

			customerList: [],
			projectList: [],
			budgetCategory: null,
			allBudget: [],
			firstTimeLoad: false,
			allProjectList: [],
			budgetCategoryList: [],
			// fileError: null,
			supplierList: [],
			customer_billing_address: null,
			customer_contact_person: null,
			supplier_billing_address: null,
			supplier_contact_person: null,
			poLineItems: [
				{
					isEditable: false,
					id: null,
					uuid: null,
					product: null,
					description: null,
					quantity: 1,
					quantity_type: null,
					discount: null,
					rate: 0,
					uom: null,
					tax_value: null,
					tax_amount: null,
					sub_total: 0,
					total: 0,
					product_type: null,
					order: 0,
				},
			],
		};
	},
	components: {
		TextInput,
		AutoCompleteInput,
		TextAreaInput,
		ImageUpload,
		ManageCategory,
		CreateDialog,
		POLineItems,
		FileUpload,
		SelectTermsCondition,
		SelectCustomerSupplier,
		SelectSupplier,
		DatePicker,
		SelectBudget,
		// Dialog,
		TooltipQuestion,
	},
	methods: {
		showOnlySupplierProducts(val) {
			this.purchase_order.show_only_supplier_products = val;
		},
		showSupplierProductChange() {
			this.$nextTick(() => {
				this.$refs.poLineItemsRef.getRelatedProduct(this.supplier.id);
			});
			this.$nextTick(() => {
				this.$refs.poLineItemsRef.showSupplierProductChange();
			});
		},
		importExcelFile() {
			this.$nextTick(() => {
				this.$refs.poLineItemsRef.importExcelFile();
			});
		},
		getPurchaseOrderItemsSample() {
			if (!this.supplier?.id) {
				this.$store.commit(SET_ERROR, [{ model: true, message: `Please Select Supplier` }]);
				return;
			}
			const _this = this;
			try {
				_this.pageLoading = true;
				let endpoint = `export/line-items/purchase-order?supplier=${this.supplier.id}&show_only_supplier_products=${this.purchase_order.show_only_supplier_products}`;
				ApiService.setHeader();
				let downloadURL = process.env.VUE_APP_API_URL + endpoint;
				downloadURL = new URL(downloadURL);
				const token = JwtService.getToken();
				downloadURL.searchParams.append("token", token);
				window.open(downloadURL, "_blank");
			} catch (error) {
				console.log(error);
			} finally {
				_this.pageLoading = false;
			}
		},
		async getAllBudget() {
			const data = await GetAllBudget();
			this.allBudget = data;
			// this.getExpense();
		},
		getRelatedBudget($event) {
			const project = $event;
			const budget_list = filter(this.allBudget, (row) => {
				return row.project == project;
			});
			let buget_data = [];
			budget_list.forEach((element, index) => {
				buget_data.push({
					text: element.name,
					disabled: element.sub_budgets.length ? true : false,
					numbering: index + 1,
					value: `budget-${element.id}`,
					cost: element.cost,
					budget_id: element.id,
					sub_budget_id: null,
				});
				element["sub_budgets"].forEach((row, index) => {
					buget_data.push({
						text: row.name,
						subNumbering: index + 1,
						value: `sub-budget-${row.id}`,
						cost: row.cost,
						budget_id: row.budget,
						sub_budget_id: row.id,
					});
				});
			});
			this.budgetCategoryList = buget_data;
		},
		hideOnScroll(scroll) {
			if (scroll > 100) {
				this.scroll_top = true;
			} else {
				this.scroll_top = false;
			}
		},
		// updateLineItems(items) {
		// 	console.log({ updateLineItems: items });
		// },
		supplierChange($event) {
			const supplier = $event && $event.id ? $event.id : null;
			if (this.$refs.poLineItemsRef) {
				this.$refs.poLineItemsRef.getRelatedProduct(supplier);
				if (supplier != this.customSupplierId) {
					this.customSupplierId = null;
					this.poLineItems = [
						{
							isEditable: false,
							id: null,
							uuid: null,
							product: null,
							description: null,
							uom: null,
							quantity: 1,
							rate: 0,
							total: 0,
							order: 1,
						},
					];
				}
			}
			// console.log("this.$refs.poLineItemsRef.updateUIID()");
			// 	this.$nextTick(() => {
			// 		if (this.$refs.poLineItemsRef?.updateUIID()) {
			// 			this.$refs.poLineItemsRef.updateUIID();
			// 		}
			// 	});
		},
		changeFrontendUUID() {
			this.$nextTick(() => {
				if (this.$refs.poLineItemsRef?.updateUIID()) {
					this.$refs.poLineItemsRef.updateUIID();
				}
			});
		},
		async getCustomer(id) {
			const param = { customer: id };
			const { billing_address, contact_person } = await GetCustomer(param);
			this.customer_billing_address = billing_address;
			this.customer_contact_person = contact_person;
		},
		async getSupplier(id) {
			const param = { supplier: id };
			const { billing_address, contact_person } = await GetCustomer(param);
			this.supplier_billing_address = billing_address;
			this.supplier_contact_person = contact_person;
		},
		// getSubGroup() {
		// 	console.log({ services_category: this.services.category });
		// },
		getCalculation(params) {
			let obj = params;
			for (const property in obj) {
				obj[property] = Number(obj[property]);
			}
			this.line_items_calculations = obj;
		},
		async updateOrCreate() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.serviceForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.serviceForm.validate()) {
				return false;
			}
			const order_length = this.poLineItems.length;
			const items = map(this.poLineItems, (row) => {
				return {
					id: row.id ? row.id : null,
					uuid: row.uuid ? row.uuid : null,
					product: row.product ? row.product : null,
					description: row.description ? row.description : null,
					quantity: row.quantity ? Number(row.quantity) : 0,
					rate: row.rate ? Number(row.rate) : 0,
					uom: row.uom ? row.uom : null,
					tax_value: row.tax_value ? row.tax_value : 0,
					tax_amount: row.tax_amount ? row.tax_amount : 0,
					sub_total: row.total ? Number(row.total) : 0,
					total: row.total ? Number(row.total) : 0,
					order: row.order ? Number(row.order) : order_length + 1,
					product_type: row.product_type ? row.product_type : "inventory",
				};
			}).filter((row) => row.product != null);
			if (items.length == 0) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: "Line Items Required" }]);
				return false;
			}
			const files = map(this.purchase_order.files, (row) => {
				return { ...row, file: null };
			});
			const formData = {
				...this.purchase_order,
				...this.line_items_calculations,
				barcode: null,
				files: files,

				frontend_uuid: this.$refs.poLineItemsRef.exel_file_uuid,
				terms_and_conditions: this.terms_and_condition.id,
				terms_and_conditions_text: this.terms_and_condition.description,
				supplier: this.supplier.id,
				supplier_address:
					this.supplier && this.supplier.billing_address_id ? this.supplier.billing_address_id : null,
				supplier_contact_person:
					this.supplier && this.supplier.contact_person_id ? this.supplier.contact_person_id : null,
				customer: this.customer.id,
				customer_address:
					this.customer && this.customer.billing_address_id ? this.customer.billing_address_id : null,
				customer_contact_person:
					this.customer && this.customer.contact_person_id ? this.customer.contact_person_id : null,
				line_items: items,
				budget:
					_this.budgetCategory && _this.budgetCategory.budget_id ? _this.budgetCategory.budget_id : 0,
				sub_budget:
					_this.budgetCategory && _this.budgetCategory.sub_budget_id
						? _this.budgetCategory.sub_budget_id
						: 0,
			};
			try {
				_this.pageLoading = true;
				if (_this.uuid) {
					const { uuid } = await UpdatePurchaseOrder(_this.uuid, formData);

					_this.$router.replace({
						name: "purchase-order-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Purchase Order has been updated." },
					]);
				} else {
					const { uuid } = await CreatePurchaseOrder(formData);

					_this.$router.replace({
						name: "purchase-order-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Purchase Order has been created." },
					]);
				}
				// clearing local storage data
				this.clearLocalData();
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		async updateFlaggedLineItems(id) {
			const index = this.flaggedLineItems.findIndex((ele) => ele.id === id);
			if (this.flaggedLineItems.length == 1) {
				this.flaggedLineItems = [];
			} else {
				this.flaggedLineItems = this.flaggedLineItems.splice(index, 1);
			}
		},
		async getPurchaseOrder() {
			this.pageLoading = true;
			this.contentLoading = true;
			await GetPurchaseOrder(this.uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.customSupplierId = data.supplier && data.supplier.id;
					this.purchase_order = {
						id: data.id,
						uuid: data.uuid,
						purchase_order_number: data.purchase_order_number,
						reference_number: data.reference_number,
						barcode: data.barcode,
						date: moment(data.date, "YYYY-MM-DD HH:II:SS").format("YYYY-MM-DD"),
						customer: data.customer && data.customer.id,
						customer_billing_address: data.customer_address ? data.customer_address.id : null,
						supplier_billing_address: data.supplier_address ? data.supplier_address.id : null,
						contact_person: null,
						supplier: data.supplier && data.supplier.id,
						project: data.project && data.project.id,
						files: [],
						terms_and_conditions: data.terms_and_conditions,
						show_only_supplier_products: Number(data.show_only_supplier_products),
						shipping_terms: data.shipping_terms ? data.shipping_terms : null,
						terms_and_conditions_text: data.terms_and_conditions_text,
						admin_remark: data.admin_remark,
					};
					this.selectedCustomer = {
						id: data.customer && data.customer.id,
						billing_address_id: data.customer_address ? data.customer_address.id : null,
						contact_person_id: data.customer_contact_person ? data.customer_contact_person.id : null,
					};
					this.selectedSupplier = {
						id: data.supplier && data.supplier.id,
						billing_address_id: data.supplier_address ? data.supplier_address.id : null,
						contact_person_id: data.supplier_contact_person ? data.supplier_contact_person.id : null,
					};

					this.customer_billing_address = data.customer_billing_address
						? data.customer_billing_address
						: null;
					this.supplier_billing_address = data.supplier_billing_address
						? data.supplier_billing_address
						: null;
					this.budgetCategory = {
						value: data.sub_budget ? `sub-budget-${data.sub_budget}` : `budget-${data.budget}`,
						budget_id: data.budget,
						sub_budget_id: data.sub_budget,
					};
					// const __lineitems = map(data.line_items, (row) => {
					// 	return { ...row, isEditable: false };
					// });
					// this.poLineItems = [...__lineitems, ...this.poLineItems];
					this.terms_and_condition = {
						id: data.terms_and_conditions,
						description: data.terms_and_conditions_text,
					};
					// this.flaggedLineItems = data.flaged_line_items;

					const _calculation = {
						tax_value: data.tax_value,
						taxable_amount: data.taxable_amount,
						tax_amount: data.tax_amount,
						discount_value: data.discount_value,
						discount_value_type: data.discount_value_type,
						discount_amount: data.discount_amount,
						discount_type: data.discount_type,
						adjustment: data.adjustment,
						tax_applied: data.tax_applied,
						adjustment_amount: data.adjustment_amount && data.adjustment_amount,
						sub_total: data.sub_total,
						total: data.total,
					};
					EventBus.$emit("reload:calculation", _calculation);

					const payload = {
						module: "purchase-order",
						id: this.purchase_order.id,
					};
					this.getProjectListiing(payload);

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Purchase Order", disabled: true },
						{ text: "Update", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoading = false;
				});
		},
		// async uploadLineItems() {
		// 	const _this = this;
		// 	if (!_this.upload_excel.file[0].file) {
		// 		this.fileError = "File is required";
		// 		return false;
		// 	} else {
		// 		this.fileError = null;
		// 	}
		// 	_this.uploading = true;
		// 	_this.exel_file_uuid = this.genrate_uuid();
		// 	const formData = new FormData();
		// 	formData.append(`excel_file`, _this.upload_excel.file[0].file);
		// 	formData.append(`frontend_uuid`, _this.exel_file_uuid);
		// 	try {
		// 		const data = await ImportLineItems(formData);
		// 		console.log(data);
		// 		// _this.line_items = items;
		// 		// _this.sub_total = toNumber(sub_total);
		// 		// _this.line_item_count = line_item_count;
		// 		// _this.is_sheet_uploaded = 1;
		// 		// if (_this.line_items.length) {
		// 		// 	_this.isLineItems = true;
		// 		// }
		// 	} catch (error) {
		// 		console.log(error);
		// 	} finally {
		// 		_this.importDialog = false;
		// 		_this.uploading = false;
		// 	}
		// },
		getCategories(param) {
			this.groupList = param;
		},
		pageTitle() {
			if (this.uuid) {
				return "Update Purchase Order";
			}
			return "Create New Purchase Order";
		},
		clearLocalData() {
			deleteData("create_purchase_order");
			deleteData("create_purchase_order_project");
			deleteData("po_line_items");
		},
		async LineItemMainTemp(uuid) {
			if (uuid) {
				// console.log(this.uuid, this.genrate_uuid());
				// console.log(this.$refs.poLineItemsRef.exel_file_uuid);
				const data = await LineItemsMainToTemp(uuid, this.$refs.poLineItemsRef.exel_file_uuid);
				this.poLineItems = data.line_items;
				this.flaggedLineItems = data.flaged_line_items;
			}
		},
		savetoLocal() {
			if (!this.uuid) {
				let create_purchase_order = {
					purchase_order_number: this.purchase_order.purchase_order_number,
					reference_number: this.purchase_order.reference_number,
					barcode: this.purchase_order.barcode,
					date: this.purchase_order.date,
					project: this.purchase_order.project,
					/* supplier: {
						id: this.supplier.id,
						contact_person_id: this.supplier.contact_person_id,
						billing_address_id: this.supplier.billing_address_id,
					}, */
					// project: this.purchase_order.project,
					//supplier: this.purchase_order.supplier,
					terms_and_conditions: this.purchase_order.terms_and_conditions,
					terms_and_conditions_text: this.purchase_order.terms_and_conditions_text,
					admin_remark: this.purchase_order.admin_remark,
				};
				saveData("create_purchase_order", create_purchase_order);
			}
		},
		savePurchaseOrderToLocal() {
			saveData("create_purchase_order_project", this.purchase_order.project);
		},
		async searchLinItems(payload) {
			const { data, flaged_line_items } = await SearchLineItem(payload);
			this.poLineItems = data;
			this.flaggedLineItems = flaged_line_items;
		},
		initLocalData() {
			// getting service local storage data
			let localSavePurchaseOrder = getData("create_purchase_order");
			if (localSavePurchaseOrder) {
				this.purchase_order.purchase_order_number = localSavePurchaseOrder.purchase_order_number;
				this.purchase_order.reference_number = localSavePurchaseOrder.reference_number;
				this.purchase_order.barcode = localSavePurchaseOrder.barcode;
				this.purchase_order.date = localSavePurchaseOrder.date;
				this.purchase_order.project = localSavePurchaseOrder.project;
				(this.selectedSupplier = {
					id:
						localSavePurchaseOrder.supplier &&
						localSavePurchaseOrder.supplier.id &&
						localSavePurchaseOrder.supplier.id,
					contact_person_id:
						localSavePurchaseOrder.supplier &&
						localSavePurchaseOrder.supplier.contact_person_id &&
						localSavePurchaseOrder.supplier.contact_person_id,
					billing_address_id:
						localSavePurchaseOrder.supplier &&
						localSavePurchaseOrder.supplier.billing_address_id &&
						localSavePurchaseOrder.supplier.billing_address_id,
				}),
					// this.purchase_order.project = localSavePurchaseOrder.project;
					//this.purchase_order.supplier = localSavePurchaseOrder.supplier;
					(this.purchase_order.terms_and_conditions = localSavePurchaseOrder.terms_and_conditions);
				this.purchase_order.terms_and_conditions_text =
					localSavePurchaseOrder.terms_and_conditions_text;
				this.purchase_order.admin_remark = localSavePurchaseOrder.admin_remark;
			}
			let localSavePurchaseOrderProject = getData("create_purchase_order_project");
			if (localSavePurchaseOrderProject) {
				this.purchase_order.project = localSavePurchaseOrderProject;
			}
			const po_items = getData("po_line_items");
			if (po_items && po_items.length) {
				this.poLineItems = [...po_items];
			}
		},
		getProjectListiing(payload) {
			this.$store.dispatch(GET_PROJUCT_DROPDOWN, payload).then(({ projects }) => {
				this.AllProjectList = projects;
				/* map(projects, (row) => {
					return { ...row, value: row.id, text: `${row.barcode} - ${row.name}` };
				}); */
			});
		},
		getRelatedProject($event) {
			const customer = $event && $event.id;
			this.projectList = filter(this.AllProjectList, (row) => {
				return row.customer == customer;
			});
			this.getRelatedBudget(this.purchase_order.project);
			if (this.projectList.length) {
				const projectExist = this.projectList.some((ele) => ele.id == this.purchase_order.project);
				if (!projectExist) {
					this.purchase_order.project = null;
				}
			}
		},
		setCategory(data) {
			const categoryExist = JSON.stringify(this.budgetCategory) === JSON.stringify(data);
			if (categoryExist) {
				this.budgetCategory = null;
			} else {
				this.budgetCategory = data;
			}
		},
	},
	beforeMount() {
		this.initLocalData();
	},
	mounted() {
		this.$store.dispatch(SET_LOCAL_DB).then(() => {
			this.groupList = this.localDB("groups", []);
			this.customerList = this.localDB("customers", []);
			this.supplierList = this.localDB("suppliers", []);
			// this.projectList = this.localDB("projects", []);
		});
		/* this.genrateBarcode(); */
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Purchase Order", disabled: true },
			{ text: "Create", disabled: true },
		]);
		const { name, params } = this.$route;
		if (name === "purchase-order-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.getPurchaseOrder();

			this.$nextTick(() => {
				this.LineItemMainTemp(params.uuid);
			});
		} else {
			this.genrateBarcode("purchase-order").then((output) => {
				this.purchase_order.barcode = output;
			});
			const payload = {
				module: "purchase-order",
				id: null,
			};
			this.getProjectListiing(payload);
		}
		if (this.purchase_order.supplier) {
			this.getSupplier(this.purchase_order.supplier);
		}
		this.getAllBudget();
		this.firstTimeLoad = true;
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
